<template>
  <div class="container">
    <busy :visible="loading" />
    <div class="card border-0 shadow" :class="loading ? 'd-none' : ''">
      <div class="card-header" v-if="!loading">
        <div class="row">
          <div class="col my-auto">
            <h3 class="card-title mb-0">{{ room.title }}</h3>
          </div>
          <div class="col-auto ms-auto my-auto">
            <h5 class="mb-0">
              <span
                class="badge badge-pill bg-primary text-capitalize"
                v-if="room.package_constraint"
              >
                <i class="far fa-star me-2"></i>
                {{ room.package_constraint }} Room
              </span>
              <span
                  class="badge badge-pill bg-info ms-3 text-capitalize"
                  v-if="room.price"
              >
                £{{room.price.toFixed(2)}}
              </span>
            </h5>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="room && room.user_has_access">
          You already have access to this content.
        </div>
        <div v-else>
          <div class="row">
            <div class="col-8 mx-auto">
              <div class="card bg-light">
                <div class="card-body" v-if="loadingCard">
                  <span class="h4">Loading...</span>
                </div>
                <div class="card-body" :class="loadingCard ? 'd-none' : ''">
                  <span class="h4" v-if="room">Card payment of £{{room.price.toFixed(2) }}</span>
                  <hr />
                  <form role="form">
                    <!-- Cardholder's Name -->
                    <div class="row mb-4">
                      <div class="col mx-auto">
                        <label>Cardholder's Name</label>
                        <input
                            type="text"
                            class="form-control"
                            name="name"
                            v-model="name"
                        />
                      </div>
                    </div>

                    <!-- Card Details -->
                    <div class="row mb-4">
                      <div class="col mx-auto">
                        <label for="name">Card Details</label>
                        <div id="payment-card-element" class="bg-white p-3"></div>
                      </div>
                    </div>

                    <!-- Payment Button -->
                    <div class="row mb-0">
                      <div class="col mx-auto text-end">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          @click.prevent="processPayment"
                          :disabled="busy"
                        >
                          <busy :visible="busy" button>Processing Payment</busy>
                          <span v-if="!busy">Make Payment</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      loadingCard: true,
      busy: false,
      paymentIntent: null,
      stripe: Stripe(process.env.VUE_APP_STRIPE_KEY),
      cardElement: null,
      name: "",
      room: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    fetchRoomData() {
      return this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/edu/rooms/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.room = data;
          this.loading = false;
        });
    },
    processPayment() {
      this.busy = true;
      this.stripe
          .confirmCardPayment(this.paymentIntent.client_secret, {
            payment_method: {
              card: this.cardElement,
            },
          })
          .then((result) => {
            if (result.error) {
              console.log(result.error);
              this.$EventBus.$emit("alert", {
                color: "danger",
                message: result.error.message,
              });
              self.hasCardErrors = true;
              this.busy = false;
              return;
            }
            this.$EventBus.$emit("alert", {
              message: "Payment Successful! Thank you.",
            });
            setTimeout(() => {
              window.location = "/webinars/" + this.room.id;
            }, 6000);
            this.busy = false;
          });
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        // hideIcon: true,
        // hidePostalCode: true,
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4",
            },
            fontFamily:
                'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px",
          },
        },
      });

      card.mount(container);
      return card;
    },
    fetchPaymentIntent() {
      return this.$axios
          .get(process.env.VUE_APP_API_URL + "/edu/rooms/" + this.room.id + "/payment-intent")
          .then(({ data }) => {
            this.paymentIntent = data;
            this.cardElement = this.createCardElement("#payment-card-element");
            this.loadingCard = false;
          });
    },
  },
  created() {
    this.fetchRoomData().then(() => {
      this.fetchPaymentIntent();
    });
  },
  filters: {
  },
};
</script>

<style>
</style>
