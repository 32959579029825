<template>
  <div class="container-fluid" v-if="room">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow">
          <div class="card-header">
            <div class="d-flex">
              <h3 class="card-title my-0">{{ room.title }}</h3>
              <h5 class="mb-0 ms-auto" v-if="!room.user_is_host">
                <span
                  class="badge badge-pill bg-primary text-capitalize"
                  v-if="room.package_constraint"
                >
                  <i class="far fa-star me-2"></i>
                  {{ room.package_constraint }} Room
                </span>
                <span
                    class="badge badge-pill bg-info ms-3 text-capitalize"
                    v-if="room.price"
                >
                  £{{room.price.toFixed(2)}}
                </span>
              </h5>
            </div>
          </div>
          <p class="alert alert-dark" style="border-radius: 0px; border: 0px" v-if="!room.user_has_access">
            <i class="far fa-info-circle me-1"></i>
            You don't currently have access to this content.
            <span v-if="room.price">
              You can <router-link :to="'/webinars/' + room.id + '/purchase'"  style="text-decoration: underline;">purchase access to this content</router-link> for £{{room.price.toFixed(2)}}.
              This content is also available free to <router-link :to="'/billing/upgrade/platinum'" style="text-decoration: underline">Platinum members</router-link>.
            </span>
            <span v-else>
              <router-link :to="'/billing/upgrade/platinum'">Sign up for platinum</router-link> to access this content
            </span>
          </p>
          <div class="card-body">
            <p class="alert alert-success" v-if="room.description">
              {{ room.description }}
            </p>
            <div class="row">
              <div class="col-md-4 mb-3" v-for="m in room.media" :key="m.id">
                <div class="card shadow-sm bg-light">
                  <div class="card-body">
                    <img
                      :src="m.cover_image"
                      alt
                      class="card-img-top w-100 mb-3"
                    />
                    <h5 class="card-title text-dark fw-bold">
                      {{ m.title }}
                    </h5>
                  </div>
                  <div class="card-footer bg-white text-dark">
                    <div class="row">
                      <div class="col my-auto">
                        <h5 class="mb-0">
                          <span class="badge badge-pill bg-info" v-if="m.starts_at">
                            Live at {{ m.starts_at | formatDateTime }}
                          </span>
                        </h5>
                      </div>
                      <div class="col-auto ms-auto my-auto">
                        <button
                            class="btn btn-outline-primary"
                            :disabled="!m.starts_at && !m.streaming"
                            @click="viewEvent(m)"
                        >
                          Watch Now
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      room: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    viewEvent(media) {
      this.$router.push(
        "/webinars/" + this.$route.params.id + "/media/" + media.id
      );
    },
    checkAvailable(media) {
      if (moment(media.start) <= moment()) {
        return true;
      } else {
        return false;
      }
    },
    fetchRoomData() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/edu/rooms/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.room = data;
        });
    },
  },
  created() {
    this.fetchRoomData();
  },
  filters: {
    formatDateTime(date) {
      if (date) {
        return moment.utc(date).local().format("YYYY-MM-DD HH:mm");
      } else {
        return "-";
      }
    },
  },
  components: {},
};
</script>

<style>
</style>
